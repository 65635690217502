<template>
    <div class="documents">
        <div class="form-container">
            <h1>{{PageTitle}}</h1>
            <div :class="['documents-container']">
                <div v-if="(cards) && cards.length > 0" :class="['documents-cards']">
                  <template v-for="(card) in cards">
                    <div :class="['fatca-card', {'disabled' : card.status === 'Архив'}]" :key="card.id" v-if="card.status !== 'Черновик'">
                        <div class="row">
                            <p class="fond-head" @click="showFund(card.fond_id)">
                                {{card.fond_name}}
                            </p>
                        </div>

                        <div class="fatca-card__body" v-if="card.doc_account_open_id">
                            <div :class="['data-row', {hide: title.name === 'fond_name'}]" v-for="title in titles" :key="title.name">
                                <p v-if="title.name!=='personal_notification'">
                                    {{title.value}}
                                </p>
                                <p v-else-if="card.files.find(file => file['file_type'] === 302)">
                                    {{title.value}}
                                </p>
                                <p v-if="title.name.includes('date')" class="data">
                                    {{date(card[title.name])}}
                                </p>
                                <p v-else-if="title.name.includes('id')" class="data">
                                    <v-icon v-if="card[title.name]" @click="download(card, title)">
                                      {{ /*card.scan ? */"note" /*: "note-blank"*/ }}</v-icon>
                                </p>
                                <p v-else-if="title.name==='account_open_notification'" class="data">
                                    <v-icon v-if="card.files.find(file => file['file_type'] === 301)" @click="download(card.files.find(file => file['file_type'] === 301))">
                                        {{ /*card.files.find(file => file['file_type'] === 301)['origin_name'].split(".")[1] === "pdf" ? "note-blank" :*/ "note" }}</v-icon>
                                </p>
                                <p v-else-if="title.name==='personal_notification'" class="data">
                                    <v-icon v-if="card.files.find(file => file['file_type'] === 302)" @click="download(card.files.find(file => file['file_type'] === 302))">
                                        {{ /*card.files.find(file => file['file_type'] === 302)['origin_name'].split(".")[1] === "pdf" ? "note-blank" :*/ "note" }}</v-icon>
                                </p>
                                <p v-else :class="['data', {'green' : card[title.name] === 'Обработано'}]">
                                    {{card[title.name]}}
                                </p>
                            </div>
                        </div>
                        <div class="fatca-card__body" v-if="card.doc_reg_person_id">
                            <div :class="['data-row', {hide: title.name === 'fond_name'}]" v-for="title in zflTitles" :key="title.name">
                                <p>
                                    {{title.value}}
                                </p>
                                <p v-if="title.name.includes('date')" class="data">
                                    {{date(card[title.name])}}
                                </p>
                                <p v-else-if="title.name.includes('id') || title.name==='doc_reg_person_id'" class="data">
                                    <v-icon v-if="card[title.name]" @click="download(card, title)">
                                      {{ /*card.scan ?*/ "note" /*: "note-blank"*/ }}
                                    </v-icon>
                                </p>
                                <p v-else :class="['data', {'green' : card[title.name] === 'Обработано'}]">
                                    {{card[title.name]}}
                                </p>
                            </div>
                        </div>
                        <!--            end screens fragment      -->

                        <div class="active message" v-if="messages" style="text-align: center">
                            <p v-for="(message, index) in messages" :key="`message-${index}`">
                                {{message.message}}
                            </p>
                        </div>
                    </div>
                  </template>
                </div>
                <div v-else class="col-12">
                    <p class="anketa">У вас нет открытых лицевых счетов</p>
                </div>

                <rwm-info
                        v-if="infoText"
                        :msg="infoText"
                        icon="warning"
                        color="#DA291C"
                />
                <div :class="['documents-info']" v-if="infoBlock">
                    <div class="anketa" v-html="infoBlock"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RwmInfo from "../../../RWMInfo/RwmInfo";
    import dateFormatter from "../../../../modules/module.date";
    import VIcon from "../../../icons/VIcon";

    export default {
        name: "PersonalAccountsPage",
        components: {VIcon, RwmInfo},
        props: {
            PageTitle: String,
            infoBlock: String,
            infoText: String,
            messages: Array,

            cards: Array,
            titles: Array, //array from objects {name: '', value: ''} where name is a key from cards array
            zflTitles: Array
        },
        methods: {
            date(date) {
                return dateFormatter(date)
            },
            showFund(fundId) {
                this.$router.push(`/cabinet/funds/fund/${fundId}`)
            },
            download(card, title="") {
                console.log(card)
              if (card.scan) {
                this.$emit('download', card.scan)
              } else if (card.origin_name) {
                  this.$emit('download', card)
              } else {
                this.$emit('download', {id: card[title.name], name: title.name})
              }
            }
        }
    }
</script>

<style scoped lang="scss">
    .documents-cards {
        display: flex;
        row-gap: 24px;
        column-gap: 32px;
        flex-direction: column;
        flex-wrap: wrap;
        @media screen and (min-width: 1200px) {
            flex-direction: row;
        }
    }
    .fatca-card {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        padding: 20px 32px;
        border: 1px solid $RWM-grey-super-light;
        border-radius: 5px;
        @media screen and (min-width: 1280px) {
            min-width: 450px;
            max-width: calc(50% - 20px);
        }

        & .alert {
            color: $RWM-red;
        }

        &.disabled {
            opacity: 0.65;
        }

        & .row {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 24px;
            justify-content: space-between;
            align-items: center;
            color: $RWM-dark;
            flex-direction: row;

            & .fond-head {
                color: $RWM-grey;
                transition: all 300ms;
                @media screen and (min-width: 1200px) {
                    cursor: pointer;
                    &:hover {
                        color: $RWM-red;
                    }
                }
            }
        }

        &__body {
            display: flex;
            flex: 1 1 auto;
            width: 100%;
            color: $RWM-grey;
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 26px;
            flex-direction: column;
            row-gap: 20px;

            & .data-row {
                display: flex;
                column-gap: 25px;
                justify-content: center;
                flex-direction: column;
                @media screen and (min-width: 1400px) {
                    flex-direction: row;
                }
                & > p {
                    text-align: center;
                    @media screen and (min-width: 1400px) {
                        max-width: 50%;
                        width: calc(50% - 10px);
                        text-align: right;
                    }
                }
                & .data {
                    text-align: center;
                    &.green {
                      color: $RWM-green;
                    }
                    @media screen and (min-width: 1400px) {
                        max-width: 50%;
                        width: calc(50% - 10px);
                        text-align: left;
                        display: flex;
                        align-items: flex-end;
                    }
                }
            }

            & .titles {
                display: flex;
                flex-direction: column;
                row-gap: 15px;
            }

            & .titles {
                text-align: right;
                min-width: 180px;
            }

            & .hide {
                display: none;
            }
        }

        & .active {
            color: $RWM-red
        }

        & .message {
            width: 100%;
            text-align: left;
            cursor: pointer;

            overflow: hidden;

            & p {
                font-weight: 500;
                font-size: 19px;
                line-height: 26px;
                overflow: hidden;

                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:hover {
                max-height: 1000px;

                & p {
                    white-space: normal;
                }
            }
        }
        & .message {
            max-height: 26px;
            transition: max-height 300ms;
        }
    }
</style>